import type { HotelType } from '../providers/app-provider/app-provider.types';
import type { GoogleCoordinate } from '../utils';
import { sortHotels } from '../utils';
import { useState, useEffect } from 'react';

let worker: Worker;

if (typeof window !== 'undefined' && typeof Worker !== 'undefined' && import.meta.url) {
  worker = new Worker(new URL('../utils/hotel-sort-worker.ts', import.meta.url));
}
export const useSortHotels = (
  sortType: string | null,
  brandCode: string | undefined,
  hotels: HotelType[],
  centerCoordinate: GoogleCoordinate | null
) => {
  const [sortedHotels, setSortedHotels] = useState<HotelType[]>([]);

  useEffect(() => {
    const fetchData = () => {
      try {
        if (worker) {
          worker.postMessage({
            sortType,
            brandCode,
            hotels,
            centerCoordinate,
          });

          const handleMessage = (event: { data: HotelType[] }) => {
            setSortedHotels(event.data);
          };

          worker.addEventListener('message', handleMessage);

          return () => {
            worker.removeEventListener('message', handleMessage);
          };
        } else {
          // If Web Worker is not available, call the function directly
          setSortedHotels(sortHotels(sortType, brandCode, hotels, centerCoordinate));
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error in useSortHotels (Web Worker):', e);
        // On Error, call the function directly
        setSortedHotels(sortHotels(sortType, brandCode, hotels, centerCoordinate));
      }
    };
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType, brandCode, hotels.length, centerCoordinate]);
  return sortedHotels.length
    ? sortedHotels
    : sortHotels(sortType, brandCode, hotels, centerCoordinate);
};
