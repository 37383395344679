import Markdown from 'markdown-to-jsx';
import * as React from 'react';
import { getTitleExpandedAbbreviation } from './locations-page.utils';

// We're "hijacking" markdown's link syntax in order to create <abbr> tags
// markdown-to-jsx doesn't handle spaces in the link's href, so we need to intercept the markdown and encode the href value
// e.g. "Hotels in New York, [NY](New York)" becomes "Hotels in New York, [NY](New%20York)"
const sanitizeTitleMarkdown = (title: string) =>
  title?.replace(
    /\[([^\]]+)\]\(([^)]+)\)/,
    (_, abbreviation, expandedName) => `[${abbreviation}](${encodeURI(expandedName)})`
  );
export const LocationsPageHeadingLevel1 = ({ title }: { title: string | null | undefined }) => {
  const titleAbbreviationExpanded = title ? getTitleExpandedAbbreviation(title) : undefined;

  return title ? (
    <div className="mb-4 flex-1">
      <h1 className="font-headline text-xl">
        <Markdown
          options={{
            createElement(type, props, children) {
              if (type === 'a') {
                const abbreviation = children;
                const expandedName = decodeURI((props as { href: string }).href);
                return React.createElement(
                  'abbr',
                  {
                    title: expandedName,
                    style: { textDecoration: 'none' },
                    key: `abbr-${JSON.stringify(children)}`,
                    'aria-hidden': true,
                  },
                  abbreviation
                );
              }
              return React.createElement(type, props, children);
            },
          }}
        >
          {sanitizeTitleMarkdown(title || '')}
        </Markdown>
      </h1>
      {titleAbbreviationExpanded && <span className="sr-only">{titleAbbreviationExpanded}</span>}
    </div>
  ) : null;
};
