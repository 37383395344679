import type { HotelAmenityOption, Maybe } from '@dx-ui/gql-types';
import type { HotelType } from '../providers/app-provider/app-provider.types';
import getOptimizedImages from './get-optimized-images';
import { getLeadRateAvailStatus } from '../components/hotel-card/utils/hotel-lead-rate-utils';

export type Breadcrumbs = {
  uri: string;
  name: string;
};

type GetSeoSchemaParams = {
  breadcrumbs?: Breadcrumbs[];
  baseUrl: string;
  language: string;
  hotels?: HotelType[];
  amenities?: Pick<HotelAmenityOption, 'name' | 'id'>[];
};

type ImageWithRatios = {
  altText?: Maybe<string>;
  ratios: { url?: Maybe<string> }[];
};
type ImageWithVariants = {
  altText?: Maybe<string>;
  variants: { url?: Maybe<string>; size: 'xs' | 'sm' | 'md' }[];
};

const getImageUrl = (image: ImageWithRatios | ImageWithVariants) => {
  return 'ratios' in image ? image.ratios[0]?.url : getOptimizedImages(image.variants, 'xs');
};

export const SEO_IMAGES = 3;
const getHotelSeoImages = (
  hotelImages: HotelType['images'] | null | undefined,
  imagesWanted: number
) => {
  if (!hotelImages) return [];

  const primaryImages = Array.isArray(hotelImages.carousel)
    ? hotelImages.carousel.slice(0, imagesWanted).map((image) => getImageUrl(image))
    : [];

  if (primaryImages.length === 0 && hotelImages.master) {
    return [getImageUrl(hotelImages.master)];
  }
  return primaryImages;
};

export const SEO_AMENITIES = 1;
const getTopThreeAmenities = (
  hotel: HotelType,
  amenities: Pick<HotelAmenityOption, 'name' | 'id'>[]
) => {
  const topAmenities: { '@type': string; name: string }[] = [];
  for (const amenity of amenities) {
    if (hotel.amenityIds.includes(amenity.id)) {
      topAmenities.push({
        '@type': 'LocationFeatureSpecification',
        name: amenity.name,
      });
      if (topAmenities.length === SEO_AMENITIES) break;
    }
  }

  return topAmenities; // Return the array of top amenities for this hotel
};

export const getSeoSchema = ({
  breadcrumbs,
  baseUrl,
  language,
  hotels,
  amenities,
}: GetSeoSchemaParams) => {
  if (!breadcrumbs || !breadcrumbs.length) return '';

  const breadcrumbItemListElements = breadcrumbs.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': `${baseUrl}${language}${breadcrumb.uri}`,
      name: breadcrumb.name,
    },
  }));
  const hotelItemListElements = (hotels ?? [])
    .slice(0, 20)
    .filter((hotel) => getLeadRateAvailStatus(hotel.display, hotel.leadRate) === 'AVAILABLE')
    .map((hotel, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'Hotel',
          name: hotel.name,
          image: getHotelSeoImages(hotel.images, SEO_IMAGES),
          offers: {
            '@type': 'Offer',
            price: hotel.leadRate?.lowest?.rateAmount,
            priceCurrency: hotel.localization?.currencyCode,
          },
          amenityFeature: getTopThreeAmenities(hotel, amenities ?? []),
          url: hotel.facilityOverview?.homeUrlTemplate,
        },
      };
    });

  const seoSchema = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbItemListElements,
      },
      ...(hotelItemListElements.length > 0
        ? [
            {
              '@type': 'ItemList',
              itemListElement: hotelItemListElements,
            },
          ]
        : []),
    ],
  };
  return JSON.stringify(seoSchema);
};
