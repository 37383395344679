import { env } from '../../constants';
import { useMetrics, useWrappedRouter } from '../../hooks';
import { Accordion } from '@dx-ui/osc-accordion';

type Interlink = {
  name: string;
  uri: string;
};

type InterlinksProps = {
  interlinks?: Interlink[];
  interlinkTitle?: string;
  index?: string;
};

export const Interlinks = ({ interlinks, interlinkTitle }: InterlinksProps) => {
  const metrics = useMetrics();
  const location_interlinks = interlinks || [];
  const { router } = useWrappedRouter();

  const handleInterlinkMetricsOnClick = (linkItemName?: string) => {
    if (interlinkTitle) {
      let data = '';
      if (interlinkTitle.indexOf('by') > -1)
        data = interlinkTitle.substring(interlinkTitle.indexOf('by') + 3);
      if (interlinkTitle.indexOf('near') > -1)
        data = interlinkTitle.substring(interlinkTitle.indexOf('near') + 5);
      const formattedLinkItemName = data.split(' ')[0];
      metrics.trackInterlinksTitleClick(
        linkItemName && formattedLinkItemName
          ? `${formattedLinkItemName}:${linkItemName}`
          : formattedLinkItemName ?? ''
      );
    }
  };

  const interLinkTitle = () => (
    <h3 className="text-primary font-sans text-base font-bold">
      <span dangerouslySetInnerHTML={{ __html: interlinkTitle || '' }} />
    </h3>
  );
  return (
    <div className="border-border border-b py-4" data-testid="location-interlinks">
      <Accordion
        className="accordion-stacked bg-transparent p-0 text-left font-sans font-bold"
        items={[
          {
            key: 'location-interlinks',
            content: (
              <section>
                <ol
                  className="grid list-none grid-cols-2 gap-2 pt-4 md:grid-cols-3"
                  data-testid="interLinkList"
                >
                  {location_interlinks.map((linkItem) => (
                    <li className="inline-block pb-4" key={linkItem.name}>
                      <a
                        className="text-primary inline-block font-normal no-underline "
                        data-testid={`interLink ${linkItem.name}`}
                        href={`${env.OHW_BASE_URL}${router.locale}/${linkItem.uri}`}
                        onClick={() => handleInterlinkMetricsOnClick(linkItem.name)}
                      >
                        {linkItem.name}
                      </a>
                    </li>
                  ))}
                </ol>
              </section>
            ),
            collapsedButtonLabel: interLinkTitle(),
            expandedButtonLabel: interLinkTitle(),
          },
        ]}
        type="multiple"
        variant="stacked"
      />
    </div>
  );
};
