import { useMemo, useEffect } from 'react';
import type {
  HotelSummaryOptions_GeocodePageQuery,
  HotelSummaryOptions_GeocodePage_VariantQuery,
  LocationCategory,
  Maybe,
} from '../gql/types';
import { useFeatureToggleConfig } from './use-feature-toggle';
import type { PageType } from '../utils';
import { getPageType } from '../utils';
import { useWrappedRouter } from './use-wrapped-router';
import { useAppDispatch } from '../providers/app-provider';

type PageAttributes = {
  categoryId?: Maybe<LocationCategory | 'resort'>;
  categoryName?: string;
  isCategoryAmenity?: boolean;
  isGlobalPage?: boolean;
  isResortPage?: boolean;
};
const defaultDreamPageAttibutes: PageAttributes = {
  categoryId: undefined,
  categoryName: '',
  isCategoryAmenity: false,
  isGlobalPage: false,
  isResortPage: false,
};
type DreamAttributesConfig = {
  id?: Maybe<LocationCategory | 'resort'>;
  slug?: string;
  singularDisplay?: string;
  pluralDisplay?: string;
  breadcrumbDisplay?: string;
  amenityIds?: string[];
  categoryIds?: string[];
};

export const useGetDreamPageAttributes = (
  data:
    | NonNullable<
        | HotelSummaryOptions_GeocodePageQuery['geocodePage']
        | HotelSummaryOptions_GeocodePage_VariantQuery['geocodePage']
      >['location']
    | undefined
) => {
  const dreamPageAttributesConfig = useFeatureToggleConfig('config-dream-attributes');
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();
  const dispatch = useAppDispatch();

  const dreamPageAttributes = useMemo(() => {
    const pageAttributes: PageAttributes = {
      ...defaultDreamPageAttibutes,
      isGlobalPage: !!data?.globalBounds,
    };

    if (dreamPageAttributesConfig?.enabled) {
      const category = data?.category;
      if (category || queryParameters?.activeFiltersState?.amenityFilters?.length) {
        const dreamAttributes: DreamAttributesConfig[] = Object.values(
          dreamPageAttributesConfig?.config ?? {}
        );
        const categoryOrAmenity = dreamAttributes?.find(
          (config) =>
            config?.id === category ||
            queryParameters?.activeFiltersState?.amenityFilters.includes(config.id ?? '')
        );
        pageAttributes.isCategoryAmenity = !!categoryOrAmenity;
        pageAttributes.isResortPage = category === 'resort';
        pageAttributes.categoryId = categoryOrAmenity?.id;
        pageAttributes.categoryName = pageAttributes.isResortPage
          ? categoryOrAmenity?.pluralDisplay
          : categoryOrAmenity?.singularDisplay;
      }
    }
    return pageAttributes;
  }, [
    data?.category,
    data?.globalBounds,
    dreamPageAttributesConfig?.config,
    dreamPageAttributesConfig?.enabled,
    queryParameters?.activeFiltersState?.amenityFilters,
  ]);

  useEffect(() => {
    //set pageType
    const pageType: PageType = getPageType(router.asPath, queryParameters);
    pageType.isGlobal = !!dreamPageAttributes.isGlobalPage;
    pageType.isResorts = !!dreamPageAttributes.isResortPage;
    pageType.isCategoryAmenity = !!dreamPageAttributes.isCategoryAmenity;
    pageType.isBrand = !!data?.brandCode;
    dispatch({ type: 'SET_PAGE_TYPES', payload: pageType });
  }, [data, dispatch, dreamPageAttributes, queryParameters, router.asPath]);

  return { pageAttributes: dreamPageAttributes };
};
