import styles from './about-locations-page.module.css';
import type { LocationAbout } from '../../gql/types';
import cx from 'classnames';

type AboutLocationsPageProp = {
  about?: LocationAbout | null;
  aboutClassName?: string;
  contentBlockClassName?: string;
  descriptionClassName?: string;
  titleClassName?: string;
};

export const AboutLocationsPage = ({
  about,
  aboutClassName,
  contentBlockClassName,
  descriptionClassName,
  titleClassName,
}: AboutLocationsPageProp) => {
  let descIndex = 0;
  let orderedListIndex = 0;
  let unOrderedListIndex = 0;
  if (about && about?.contentBlocks?.length > 0) {
    return (
      <div className={cx(styles.container, aboutClassName)} data-testid="about-contentBlocks">
        {about?.contentBlocks?.map((contentBlock) => {
          const title = contentBlock?.title ? `<h2>${contentBlock?.title}</h2>` : null;
          return title ? (
            <div key={`content-block-${contentBlock?.title}`} className={contentBlockClassName}>
              <div
                className={cx(
                  {
                    'text-primary text-base font-bold': !titleClassName,
                  },
                  titleClassName
                )}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {contentBlock.descriptions?.map((description) => {
                return description ? (
                  <div
                    className={cx(
                      {
                        'p-2 text-xs': !descriptionClassName,
                      },
                      descriptionClassName
                    )}
                    dangerouslySetInnerHTML={{ __html: description }}
                    key={`content-title-${descIndex++}`}
                  />
                ) : null;
              })}
              {contentBlock?.orderedList?.map((orderedList) => {
                return orderedList ? (
                  <div
                    className="p-2 text-xs"
                    dangerouslySetInnerHTML={{ __html: orderedList }}
                    key={`contentBlock-orderedList-${orderedListIndex++}`}
                  />
                ) : null;
              })}
              {contentBlock?.unorderedList?.map((unorderedList) => {
                return unorderedList ? (
                  <div
                    className="p-2 text-xs"
                    dangerouslySetInnerHTML={{ __html: unorderedList }}
                    key={`contentBlock-orderedList-${unOrderedListIndex++}`}
                  />
                ) : null;
              })}
            </div>
          ) : null;
        })}
      </div>
    );
  }
  return null;
};
