import { useCallback } from 'react';
import type { HotelType } from '../../providers/app-provider/app-provider.types';
import { isAffiliateHotel, useMetrics, useRenovationChip, useWrappedRouter } from '../../hooks';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { prefixPublicAssetUriWithAppPrefix } from '../../utils';
import { isLocalCurrencySameAsHotelCurrency } from '../hotel-card/utils/hotel-info-utils';
import MapInfoWindow from './map.info-window';
import MapMarker, { MAP_MARKER_COLOR } from './map.marker';
import MapStaticMarker from './map.static-marker';
import MapMarkers from './map.markers';
import { getMapMarkerIcon, getMarkerPrice } from './map.utils';
import { InfoWindow } from '@dx-ui/osc-map';
import type { HotelBadgeTypes } from '../hotel-card/hotel-badges';
import { HotelBadges } from '../hotel-card/hotel-badges';
import { formatAndConvertPrice, useCurrencyConversion } from '@dx-ui/osc-currency-converter';

export const LocationsMapMarkers = ({
  centerCoordinate,
  hotelsInBounds,
  visibleHotels,
}: {
  centerCoordinate: {
    lat: number;
    lng: number;
  };
  hotelsInBounds: Record<string, HotelType>;
  visibleHotels: string[];
}) => {
  const { activeProperty, selectedCurrency, selectedCtyhocn, shouldUsePoints } = useAppState();
  const { currenciesError, currenciesMap } = useCurrencyConversion();
  const dispatch = useAppDispatch();
  const metrics = useMetrics();
  const { router } = useWrappedRouter();
  const { renoChipConfig } = useRenovationChip();
  const disableMarkerPrice = !!(visibleHotels.length > 150);

  const handleMapMarkerClicked = useCallback(
    (ctyhocn: string, name?: string | null) => {
      dispatch({ type: 'SET_PDP_CTYHOCN', payload: ctyhocn });
      metrics.setBrandDetail({
        ctyhocn,
        view: 'Map',
        name: name || '',
        index: 1,
      });
    },
    [dispatch, metrics]
  );

  return (
    <MapMarkers
      activeMarker={activeProperty || selectedCtyhocn}
      iconClusterPath="/modules/assets/svgs/map/cluster/HI.svg"
    >
      <MapStaticMarker
        icon={prefixPublicAssetUriWithAppPrefix('/assets/map-pin-active.png')}
        position={centerCoordinate}
        shouldShowCenterMarker={true}
      />
      {visibleHotels.map((hotelCtyhocn) => {
        const hotel = hotelsInBounds[hotelCtyhocn];
        if (hotel) {
          const {
            ctyhocn,
            localization,
            brandCode: hotelBrandCode,
            name,
            address,
            amenityIds,
          } = hotel;

          const badges: HotelBadgeTypes[] = [];
          if (amenityIds.includes('newHotel')) badges.push('new');
          if (renoChipConfig?.includes(ctyhocn)) badges.push('reno');

          const coordinate = localization?.coordinate || null;
          const price =
            currenciesMap.size &&
            !isLocalCurrencySameAsHotelCurrency(
              selectedCurrency || '',
              hotel?.localization?.currencyCode || ''
            )
              ? formatAndConvertPrice({
                  basePrice: !hotel?.leadRate?.lowest?.rateAmount
                    ? undefined
                    : hotel.leadRate?.lowest?.rateAmount,
                  currencyQueryError: !!currenciesError,
                  fromCurrency: currenciesMap?.get('USD'),
                  language: router.locale,
                  toCurrency: currenciesMap.get(selectedCurrency || ''),
                }) || hotel?.leadRate?.lowest?.rateAmountFmt
              : hotel?.leadRate?.lowest?.rateAmountFmt;
          const points = hotel?.leadRate?.hhonors?.lead?.dailyRmPointsRateNumFmt;

          if (!coordinate) return null;
          const position = { lat: coordinate.latitude, lng: coordinate.longitude };
          const isActive = activeProperty === ctyhocn || selectedCtyhocn === ctyhocn;
          const suppressBrandLogo = isAffiliateHotel(hotel?.display);
          return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <MapMarker
              isActive={isActive}
              color={
                !!shouldUsePoints && !!points ? MAP_MARKER_COLOR['POINTS_EXPLORER'] : undefined
              }
              icon={prefixPublicAssetUriWithAppPrefix(
                getMapMarkerIcon(isActive, disableMarkerPrice)
              )}
              id={ctyhocn}
              key={`map-marker-item-${ctyhocn}`}
              label={getMarkerPrice(disableMarkerPrice, shouldUsePoints, points, price)}
              onClick={() => handleMapMarkerClicked(ctyhocn, name)}
              onMouseOut={() => dispatch({ type: 'UNSET_HIGHLIGHTED_MAP_CTYHOCN' })}
              onMouseOver={() =>
                dispatch({ type: 'SET_HIGHLIGHTED_MAP_CTYHOCN', payload: ctyhocn })
              }
              position={position}
            >
              <MapInfoWindow
                onCloseClick={() => dispatch({ type: 'SET_PDP_CTYHOCN', payload: null })}
                position={position}
              >
                <InfoWindow
                  address={address ? `${address.addressLine1}, ${address.city}` : ''}
                  brandCode={suppressBrandLogo ? undefined : hotelBrandCode || 'HI'}
                  ctyhocn={ctyhocn}
                  hotelBadges={<HotelBadges badges={badges} />}
                  name={name || ''}
                  containerClassName="w-56 sm:w-fit"
                />
              </MapInfoWindow>
            </MapMarker>
          );
        }
        return null;
      })}
    </MapMarkers>
  );
};
