import { useWrappedRouter } from '../../hooks';
import { Breadcrumbs as OSCBreadCrumbs } from '@dx-ui/osc-breadcrumbs';

type BreadcrumbsProps = {
  breadcrumbs: { name: string; uri: string }[];
};

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const { router } = useWrappedRouter();

  if (breadcrumbs.length === 0) return null;

  const breadCrumbs = breadcrumbs?.map((bc) => ({
    name: bc.name,
    uri: `/${router.locale}${bc.uri}`,
  }));
  return <OSCBreadCrumbs breadcrumbs={breadCrumbs} />;
};
